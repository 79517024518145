import React, { useState, useLayoutEffect } from "react";
import "../Css/Gallery.css";
import Tabs from "./Tabs";
import Items from "./Items";
import MyBackgroundImage from "../Images/gallery/jpeg-optimizer_banner1.webp";
import GalleryData from "../Components/GalleryData";
// import Bounce from 'react-reveal/Bounce';
import {MetaTags} from "react-meta-tags";
import Footer from "../Components/Footer";

const Gallery = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  const [data, setData] = useState(GalleryData);

  // Store Category in CategoryData
  const categoryData = GalleryData.map((value) => {
    return value.Category;
  });
  ////////////

  const tabsData = ["all", ...new Set(categoryData)];

  // for all categoryData set
  const filterCategory = (Category) => {
    if (Category == "all") {
      setData(GalleryData);
      return;
    }
    const filteredData = GalleryData.filter((value) => {
      return value.Category == Category;
    });

    setData(filteredData);
  };

  return (
    <>

{/* -----------------SEO Start--------------------- */}
{/* ========= SEO ========== */}

<MetaTags>
        <title>
        Best Banquet Hall in Kandivali | Gurukrupa Party Hall
        </title>
        <meta name="title" title=" Best Banquet Hall in Kandivali | Gurukrupa Party Hall"/>
        <meta
          property="og:title"
          content=" Best Banquet Hall in Kandivali | Gurukrupa Party Hall"
        />
        <meta
          name="description"
          content="Experience excellence at the Best Banquet Hall in Kandivali. Elevate your events with unparalleled service, elegant spaces, and a touch of sophistication."
        />
       
        <meta
          property="og:description"
          content="Experience excellence at the Best Banquet Hall in Kandivali. Elevate your events with unparalleled service, elegant spaces, and a touch of sophistication."
        />
        <meta
          name="keywords"
          content="Party Hall in Kandivali,
          Banquet Hall in Kandivali,
          Best Banquet Hall in Kandivali,
          Top Party Hall in Kandivali,
          Best Hall in Kandivali"
        />
<meta name="google-site-verification" content="GrMY0Kd9JZI27nSpKcOw0ZtFCtHS5dc4sS6bAuhKJdk" />
        <meta property="article:tag" content="Best Hall in Kandivali" />
        <meta property="article:tag" content="Top Party Hall in Kandivali" />
        <meta property="article:tag" content="Best Banquet Hall in Kandivali" />
        <link rel="canonical" href="https://gurukrupapartyhall.in/best-banquet-hall-kandivali" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
      
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="" />
       
        
      </MetaTags>

      {/* ======== Seo end ======= */}
      {/* -----------------SEO End--------------------- */}

      {/* ///////////////////////////////// section 1 ////////////////////////////////// */}
     {/* --------------------------banner Section----------------------------- */}
     <section className="headingSection">
        <div className=" ">
          <div
            className="container d-flex justify-content-end align-content-center aos-init aos-animate"
            data-aos="fade-right"
          >
            <div className="headingText">
              <h1 className="d-none">best-banquet-hall-kandivali</h1>
              <h2>Gallery</h2>
             
            </div>
          </div>
        </div>
      </section>

      {/* ///////////////////////////////// section 2 ////////////////////////////////// */}

      <div className="Gallery-Section-2 mt-5">
        <div className="Gallery-Card">
          <div className="container">
            <div className="row">
              <div className="col-md-12 wide-100">
                {/* <---------------------tab scroller---------------> */}
               
                <Tabs filterCategory={filterCategory} tabsData={tabsData}  />
                <Items data={data} />
              </div>
            </div>
          </div>
        </div>
        <p className="d-none">Welcome to our gallery, where we showcase the splendor of the best banquet hall in Kandivali. As you explore the images captured within our walls, you'll witness the versatile and enchanting spaces that have played host to countless memorable celebrations. From intimate gatherings to grand events, our banquet hall exudes a timeless charm and sophistication that sets us apart as the preferred venue for joyous occasions. The attention to detail, vibrant atmosphere, and exceptional service captured in these images reflect our commitment to providing an unparalleled experience for every guest.

Step into a visual journey that encapsulates the essence of celebrations held at the best banquet hall in Kandivali. Our gallery is a testament to the cherished and seamless moments we've had the privilege to be a part of. From beautifully decorated interiors to the joyous faces of our guests, each image tells a story of unparalleled hospitality and the vibrant energy that defines our banquet hall. Explore the visual tapestry that is a hallmark of our commitment to making every event truly special at the best banquet hall in Kandivali.

</p>
      </div>

      <Footer />
    </>
  );
};

export default Gallery;
