import React from 'react';
import "../Css/Item.css";
// import Zoom from 'react-reveal/Zoom';
const Items = ({ data }) => {
    return (
        <>
            <div className='row'>
                {
                    data.map((value) => {
                        const { id, CImage } = value

                        return (
                            <>
                                {/* <Zoom> */}
                                    <div className="col-lg-4 col-md-6 my-3" key={id}>
                                        <div className="card bg-light text-center">
                                            <img src={CImage} alt="party decoration" />
                                            {/* <div className='Card-text'>
                                                <h2>{CategoryName}</h2>
                                            </div> */}
                                        </div>
                                    </div>
                                {/* </Zoom> */}
                            </>
                        )
                    })
                }

            </div>

            
        </>
    )
}

export default Items