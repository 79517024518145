import React, { useLayoutEffect } from "react";

import "../Css/Service.css";
import {Link} from "react-router-dom";
import banq from "../Images/gallery/jpeg-optimizer_hall.webp";
import room from "../Images/gallery/jpeg-optimizer_master-room.webp";
import catering from "../Images/gallery/jpeg-optimizer_cat1.webp";
import decor from "../Images/gallery/jpeg-optimizer_stage2.webp"
import Footer from "../Components/Footer";
import {MetaTags} from "react-meta-tags";
const Service = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div>
       {/* ========= SEO ========== */}

    <MetaTags>
        <title>
        100 to 150 Capacity Hall in Kandivali | Gurukrupa Party Hall
        </title>
        <meta name="title" title="100 to 150 Capacity Hall in Kandivali | Gurukrupa Party Hall"/>
        <meta
          property="og:title"
          content="100 to 150 Capacity Hall in Kandivali | Gurukrupa Party Hall"
        />
        <meta
          name="description"
          content="Discover the perfect venue with a 100-150 Capacity Hall in Kandivali. Host unforgettable events with our elegant spaces."
        />
       
        <meta
          property="og:description"
          content="Discover the perfect venue with a 100-150 Capacity Hall in Kandivali. Host unforgettable events with our elegant spaces. "
        />
        <meta
          name="keywords"
          content="Party Hall in Kandivali,
          Banquet Hall in Kandivali,
          Best Banquet Hall in Kandivali,
          Top Party Hall in Kandivali,
          Best Hall in Kandivali"
        />
<meta name="google-site-verification" content="GrMY0Kd9JZI27nSpKcOw0ZtFCtHS5dc4sS6bAuhKJdk" />
        <meta property="article:tag" content="Best Hall in Kandivali" />
        <meta property="article:tag" content="Top Party Hall in Kandivali" />
        <meta property="article:tag" content="Best Banquet Hall in Kandivali" />
        <link rel="canonical" href="https://gurukrupapartyhall.in/top-party-hall-kandivali" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
      
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="" />
       
        
      </MetaTags>

      {/* ======== Seo end ======= */}
        {/* --------------------------banner Section----------------------------- */}
      <section className="headingSection">
        <div className=" ">
          <div
            className="container d-flex justify-content-end align-content-center aos-init aos-animate"
            data-aos="fade-right"
          >
            <div className="headingText">
              <h1 className="d-none">top-party-hall-kandivali</h1>
              <h2>Services</h2>
              
            </div>
          </div>
        </div>
      </section>
      <section className='mt-lg-5 '>
    <div className='container'>
    <div
  className="row feature-row g-0 wow slideInUp animated"
  data-wow-delay="300ms"
  style={{
    visibility: "visible",
    animationDelay: "300ms",
    animationName: "slideInUp"
  }}
>
  <div className="image-column col-lg-4">
    <div className="inner-column">
      <div className="image-box">
        <figure className="image overlay-anim">
          <img src={banq} alt="top-party-hall-kandivali" />
        </figure>
      </div>
    </div>
  </div>
  <div className="content-column col-lg-8">
    <div className="inner-column">
      <div className="content-box">
        <div className="sec-title">
          <span className="sub-title">Banquet Hall</span>
          <h2>Air Conditioned</h2>
         <p>
         
         Welcome to Gurukrupa Party Hall in Kandivali, where the synthesis of timeless elegance and unrivaled service defines our essence. As passionate creators of unforgettable experiences, we take pride in being more than just a venue; we are dedicated curators of exceptional moments. Our centrally air-conditioned hall, with a capacity ranging from 100 to 150, provides the perfect setting for every occasion, ensuring comfort and flexibility.

At Gurukrupa Party Hall, we transcend expectations through our state-of-the-art facilities and culinary expertise. We redefine elegance, transforming dreams into reality with meticulous attention to detail. Our commitment is to host your special occasions, creating memories that linger long after the last toast. Trust us to elevate your events in our 100 to 150 Capacity Hall in Kandivali, where every celebration becomes an extraordinary experience.
 </p>
        </div>
        <Link
          to="/best-hall-kandivali"
          className="theme-btn btn-style-one read-more"
        >
      Enquire now
        </Link>
        {/* <figure className="image-2">
          <img src="images/resource/icon-3.png" alt="top-party-hall-kandivali" />
        </figure> */}
      </div>
    </div>
  </div>
</div>
    </div>

</section>
<section className='mt-lg-5'>
    <div className='container'>
    <div
  className="row feature-row g-0 wow slideInUp animated"
  data-wow-delay="300ms"
  style={{
    visibility: "visible",
    animationDelay: "300ms",
    animationName: "slideInUp"
  }}
>
  <div className="image-column col-lg-4">
    <div className="inner-column">
      <div className="image-box">
        <figure className="image overlay-anim">
          <img src={catering} alt="top-party-hall-kandivali" />
        </figure>
      </div>
    </div>
  </div>
  <div className="content-column col-lg-8">
    <div className="inner-column">
      <div className="content-box">
        <div className="sec-title">
          <span className="sub-title">Catering </span>
          <h2>Indoor-Outdoor Catering</h2>
          <p>
          
In our party hall, we specialize in creating memorable experiences through exquisite cuisine and impeccable service. Our dedicated team ensures every detail is perfected, making your event extraordinary.For off-site events at your venue, our catering extends beyond our walls. We bring the same passion for culinary excellence and hospitality, crafting bespoke menus to suit your occasion. Trust us to turn your celebrations into exceptional moments, whether in our hall or at your chosen location.

          </p>
        </div>
        <Link
          to="/best-hall-kandivali"
          className="theme-btn btn-style-one read-more"
        >
      Enquire now
        </Link>
        {/* <figure className="image-2">
          <img src="images/resource/icon-3.png" alt="top-party-hall-kandivali" />
        </figure> */}
      </div>
    </div>
  </div>
</div>
    </div>

</section>
<section className='mt-lg-5'>
    <div className='container'>
    <div
  className="row feature-row g-0 wow slideInUp animated"
  data-wow-delay="300ms"
  style={{
    visibility: "visible",
    animationDelay: "300ms",
    animationName: "slideInUp"
  }}
>
  <div className="image-column col-lg-4">
    <div className="inner-column">
      <div className="image-box">
        <figure className="image overlay-anim">
          <img src={room} alt="top-party-hall-kandivali" />
        </figure>
      </div>
    </div>
  </div>
  <div className="content-column col-lg-8">
    <div className="inner-column">
      <div className="content-box">
        <div className="sec-title">
          <span className="sub-title">Accomodation</span>
          <h2>Room Facilities</h2>
          <p>
          In our rooms Immerse yourself in sophistication and comfort as we redefine hospitality. Our meticulously designed 2 master rooms with 4 bathrooms offer a perfect blend of elegance and modern amenities, ensuring an unforgettable stay. We have rooms totaling over 1000sq. feet, and can accommodate up to 25-30 visitors with all comfort and amenities. Experience personalized service and indulge in the epitome of relaxation. Your journey with us is not just a stay; it's a celebration of refined hospitality.

          </p>
        </div>
        <Link
          to="/best-hall-kandivali"
          className="theme-btn btn-style-one read-more"
        >
      Enquire now
        </Link>
        {/* <figure className="image-2">
          <img src="images/resource/icon-3.png" alt="top-party-hall-kandivali" />
        </figure> */}
      </div>
    </div>
  </div>
  
  
</div>
    </div>

</section>
<section className='mt-lg-5'>
    <div className='container'>
    <div
  className="row feature-row g-0 wow slideInUp animated"
  data-wow-delay="300ms"
  style={{
    visibility: "visible",
    animationDelay: "300ms",
    animationName: "slideInUp"
  }}
>
  <div className="image-column col-lg-4">
    <div className="inner-column">
      <div className="image-box">
        <figure className="image overlay-anim">
          <img src={decor} alt="top-party-hall-kandivali" />
        </figure>
      </div>
    </div>
  </div>
  <div className="content-column col-lg-8">
    <div className="inner-column">
      <div className="content-box">
        <div className="sec-title">
          <span className="sub-title">Decoration</span>
          <h2>Customized Decor</h2>
          <p>
          Our exceptional party hall decoration service! We specialize in transforming spaces into enchanting realms, bringing your vision to life with creativity and flair. Our dedicated team of decorators is passionate about crafting personalized atmospheres that reflect your unique style and preferences. From elegant weddings to lively celebrations, we pride ourselves on turning ordinary spaces into extraordinary experiences. Trust us to add the perfect touch of magic to your event, creating memories that last a lifetime.
          </p>
        </div>
        <Link
          to="/best-hall-kandivali"
          className="theme-btn btn-style-one read-more"
        >
      Enquire now
        </Link>
        {/* <figure className="image-2">
          <img src="images/resource/icon-3.png" alt="top-party-hall-kandivali" />
        </figure> */}
      </div>
    </div>
  </div>
  
  
</div>
    </div>

</section>
<Footer/>
    </div>
  )
}

export default Service