
////////////// Door Images //////////////////

import wed from "../Images/gallery/jpeg-optimizer_wedding (1).webp";
import wed1 from "../Images/gallery/jpeg-optimizer_hall.webp"
import wed2 from "../Images/gallery/jpeg-optimizer_stage1.webp"
import bday from "../Images/gallery/jpeg-optimizer_birthday.webp";
import live from "../Images/gallery/jpeg-optimizer_conferences.webp";
import haldi from "../Images/gallery/jpeg-optimizer_haldi.webp";
import mehandi from "../Images/gallery/jpeg-optimizer_mehandi.webp";
import baby from "../Images/gallery/jpeg-optimizer_babyshower.webp";
import aaniv from "../Images/gallery/jpeg-optimizer_anniversary.webp";
// import retire from "../Images/gallery/jpeg-optimizer_retirement.jpeg'";

const GalleryData = [
  {
    id: 1,
    Category:"Wedding Decor",
    CategoryName:"wedding",
    CImage:wed1,
    Imgalt:"Party-Hall-in-Kandivali",
  },
  {
    id: 2,
    Category:"Corporate event",
    CategoryName:"Main Door",
    CImage:live,
    Imgalt:"Party-Hall-in-Kandivali",
  },
  {
    id: 3,
    Category:"Functions",
    CategoryName:"Mehandi",
    CImage:mehandi,
    Imgalt:"Party-Hall-in-Kandivali",
  },
  {
    id: 4,
    Category:"Wedding Decor",
    CategoryName:"wedding",
    CImage:wed2,
    Imgalt:"Party-Hall-in-Kandivali",
  },
  {
    id: 5,
    Category:"Baby Shower",
    CategoryName:"baby",
    CImage:baby,
    Imgalt:"Best-Party-Hall-in-Kandivali",
  },
  {
    id:6,
    Category:"Anniversary",
    CategoryName:"Hall",
    CImage:aaniv,
    Imgalt:"100 to 150 Capacity Hall in Kandivali",
  },

  {
    id: 7,
    Category:"Birthday",
    CategoryName:"Hall",
    CImage:bday,
    Imgalt:"Party-Hall-in-Kandivali",
  },
  {
    id: 8,
    Category:"Haldi",
    CategoryName:"Hall",
    CImage:haldi,
    Imgalt:"100 to 150 Capacity Hall in Kandivali",
  },
  {
    id: 9,
    Category:"Wedding Decor",
    CategoryName:"wedding",
    CImage:wed,
    Imgalt:"Best Banquet Hall in Kandivali",
  },
  
  
  {
    id: 13,
    Category:"Baby Shower",
    CategoryName:"baby",
    CImage:baby,
    Imgalt:"Banquet Hall in Kandivali",
  },
  
  
];

export default GalleryData;

