import React from 'react'
import "../Css/Footer.css";
import {Link} from "react-router-dom";
// import Logo from "../images/Green Farm.png";
const Footer = () => {
  return (
    <div>
        <footer className="footer-section">
  <div className="container">
    <div className="footer-cta pt-5 pb-5">
      <div className="row">
        <div className="col-lg-5 col-md-6  mb-30">
          <div className="single-cta">
            <i className="fa fa-map-marker" />
            <div className="cta-text pt-2">
            <a href="https://maps.app.goo.gl/jZTKhaN25xAuKV1b7">  <h4>Find us</h4>
              <span>
               Kandivali West, Mumbai, Maharashtra 400067</span></a>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 mb-30">
          <div className="single-cta">
            <i className="fa fa-phone" />
            <div className="cta-text pt-2">
          <a href="tel:+918169684124">    <h4>Call us</h4>
              <span> 8169684124 / 8169347797</span></a>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-12 mb-30">
          <div className="single-cta">
            <i className="fa fa-envelope-open" />
            <div className="cta-text pt-2">
         <h4>Mail us</h4>
          <a href="mailto:gurukrupaparythall@gmail.com">  <span> gurukrupaparythall@gmail.com</span> </a> 
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="footer-content pt-5 pb-5">
      <div className="row">
        <div className="col-xl-4 col-lg-4 mb-50">
          <div className="footer-widget">
            <div className="footer-logo">
              <a href="index.html">
                {/* <img
                  src={Logo}
                  className="img"
                  alt="logo"
                /> */}
              </a>
            </div>
            <div className="footer-text">
              <p>
              Welcome to our Gurukrupa party hall, where elegance meets convenience.Our Gurukrupa party hall in kandivali offers classy and versatile spaces perfect for any event on your calendar, from an intimate engagement party to a huge anniversary party bash or corporate events. </p>
            </div>
                <div className="footer-widget">
            {/* <div className="footer-widget-heading">
              <h3></h3>
            </div> */}
          
          
          </div>
           
          </div>
        </div>
        <div className="col-xl-4 col-lg-4 col-md-6 mb-30">
          <div className="footer-widget">
            <div className="footer-widget-heading">
              <h3>Useful Links</h3>
            </div>
            <ul className="m-0 p-0">
              <li>
                <Link to="/">Home</Link>
              </li>
             
             
           
              <li>
                <Link to="/banquet-hall-kandivali">About Us</Link>
              </li>     
              <li>
                <Link to="/top-party-hall-kandivali">Services</Link>
              </li>
              <li>
                <Link to="/best-banquet-hall-kandivali">Gallery</Link>
              </li>
            
            </ul>
          </div>
      
        </div>
        <div className="col-xl-4 col-lg-4 col-md-6 mb-50">
          <div className="footer-widget">
            <div className="footer-widget-heading">
              <h3>Address</h3>
            </div>
            <div className="footer-text mb-25">
              <p>
              Bunglow No, 10, RSC Rd Number 55, near Oxford Public School, Kandivali, Sector 5 Charkop, Kandivali West, Mumbai, Maharashtra 400067
              </p>
            </div>
            {/* <div className="footer-social-icon mt-lg-5">
              <span>Follow us</span>
              <a href="#">
                <i className="fa fa-facebook-f facebook-bg" />
              </a>
              <a href="#">
                <i className="fa fa-twitter twitter-bg" />
              </a>
              <a href="#">
                <i className="fab fa-google-plus-g google-bg" />
              </a>
            </div> */}
          </div>
      
        </div>
      </div>
    </div>
  </div>
  <div className="copyright-area">
    <div className="container">
      <div className="row">
        <div className="col-xl-6 col-lg-6 text-center text-lg-left">
          <div className="copyright-text">
            <p>
              Copyright © 2018, All Right Reserved{" "}
              <br/>
              <a href="">Gurukrupa Party Hall</a>
            </p>
            <p>
              Developed By &nbsp;
              <a href="https://skdm.in/">Shree Krishna Digital Marketing</a>
            </p>
          </div>
        </div>
        <div className="col-xl-6 col-lg-6 d-none d-lg-block text-right">
          <div className="footer-menu">
            {/* <ul>
            <li>
                <Link to="/">Home</Link>
              </li>
             
             
           
              <li>
                <Link to="wedding-planners-malad">About Us</Link>
              </li>
             
             
              <li>
                <Link to="best-event-planner-malad">Services</Link>
              </li>
              <li>
                <Link to="decorator-near-malad">Contact us</Link>
              </li>
            
            </ul> */}
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>

<div className="floattingButton">
  {/* <a href="#" className="float" id="menu-share">
    <i className="fa fa-link my-float" />
  </a> */}
  <ul>
  <li>
      <a href="https://wa.me/8169684124?text=Hello!">
        <i className="fa fa-whatsapp my-float" />
      </a>
    </li>
    <li>
      <a href="https://www.instagram.com/gurukrupa_partyhall/">
        <i className="fa fa-instagram my-float" />
      </a>
    </li>
    <li>
      <a href="https://www.facebook.com/profile.php?id=61553229465002">
        <i className="fa fa-facebook my-float" />
      </a>
    </li>
  </ul>
</div>

    </div>
  )
}

export default Footer