import React, { useLayoutEffect } from "react";
import "../Css/Home.css";
import { Formik, Form, Field, ErrorMessage } from "formik";
import $ from 'jquery';
import {Link} from 'react-router-dom';
// import "../Css/responsive.css";
import Carousel from "react-bootstrap/Carousel";
import Zoom from "react-reveal/Zoom";
import food from "../Images/gallery/jpeg-optimizer_banner1.webp";
import sprouts from "../Images/gallery/jpeg-optimizer_hall.webp";
import fimage1 from  "../Images/gallery/jpeg-optimizer_hallac.webp";
// import Fade from "react-reveal/Fade";
import Footer from "../Components/Footer";
import wedarch from "../Images/gallery/jpeg-optimizer_wedding-arch.png";
import decor from "../Images/gallery/jpeg-optimizer_decoration.png";

import wed from "../Images/gallery/jpeg-optimizer_wedding (1).webp";
import bday from "../Images/gallery/jpeg-optimizer_birthday.webp";
import live from "../Images/gallery/jpeg-optimizer_conferences.webp";
import haldi from "../Images/gallery/jpeg-optimizer_haldi.webp";
import mehandi from "../Images/gallery/jpeg-optimizer_mehandi.webp";
import baby from "../Images/gallery/jpeg-optimizer_babyshower.webp";
import aaniv from "../Images/gallery/jpeg-optimizer_anniversary.webp";
import retire from "../Images/gallery/jpeg-optimizer_retirement.webp";
import {MetaTags} from "react-meta-tags";
const Home = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div>

        {/* ========= SEO ========== */}

    <MetaTags>
        <title>
        Party Hall in Kandivali | Gurukrupa Party Hall
        </title>
        <meta name="title" title="Party Hall in Kandivali | Gurukrupa Party Hall"/>
        <meta
          property="og:title"
          content="Party Hall in Kandivali | Gurukrupa Party Hall"
        />
        <meta
          name="description"
          content="Discover the perfect venue for your celebrations! Our Party Hall in Kandivali offers a blend of elegance and excitement, creating memorable events."
        />
       
        <meta
          property="og:description"
          content="Discover the perfect venue for your celebrations! Our Party Hall in Kandivali offers a blend of elegance and excitement, creating memorable events."
        />
        <meta
          name="keywords"
          content="Party Hall in Kandivali,
          Banquet Hall in Kandivali,
          Best Banquet Hall in Kandivali,
          Top Party Hall in Kandivali,
          Best Hall in Kandivali"
        />

<meta name="google-site-verification" content="GrMY0Kd9JZI27nSpKcOw0ZtFCtHS5dc4sS6bAuhKJdk" />
        <meta property="article:tag" content="Best Hall in Kandivali" />
        <meta property="article:tag" content="Top Party Hall in Kandivali" />
        <meta property="article:tag" content="Best Banquet Hall in Kandivali" />
        <link rel="canonical" href="https://gurukrupapartyhall.in" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
      
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="" />
       
        
      </MetaTags>

      {/* ======== Seo end ======= */}
          <Carousel>
        <Carousel.Item>
        
        <div id="thirdSlide" className="">
            {/* <img className="d-block w-100 h-100" src={banner1} alt="" /> */}
            </div>
          
          <Carousel.Caption>
         <div className="content-box">
  <div className="content">
    <div className="inner">
    <Zoom>
      <h1 className="d-none">Party Hall in Kandivali | Gurukrupa Party Hall</h1>
      <h2>
     <span> Gurukrupa Party Hall </span><br />
     
      </h2>
      <div className="text">
      Where Dreams Become Celebrations, and Celebrations Become Timeless.
      </div>
      </Zoom>
          </div>
  </div>
</div>

        </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item>
         <div id="firstSlide" className="">
            {/* <img className="d-block w-100 h-100" src={banner1} alt="" /> */}
            </div>
          <Carousel.Caption>

         <div className="content-box">
  <div className="content">
    <div className="inner">
    <Zoom>
      <h2>
     <span> Seamless Elegance</span><br />
     
      </h2>
      <div className="text">
        Gurukrupa Party Hall Sets the Standard for Celebrations.
      </div>
      {/* <div className="basr-social-share social">
  <ul className="">
    <li>
      <a className="facebook" href="">
        <i className="fa fa-facebook" />
        <span>Facebook</span>
      </a>
    </li>
    <li>
      <a className="twitter" href="">
        <i className="fa fa-twitter" />
        <span>Twitter</span>
      </a>
    </li>
    <li>
      <a className="googleplus" href="">
        <i className="fa fa-google-plus" />
        <span>Google Plus</span>
      </a>
    </li>
   
    <li>
      <a className="tumblr" href="">
        <i className="fa fa-instagram" />
        <span>Tumblr</span>
      </a>
    </li>
  </ul>
</div> */}
      </Zoom>
      
    </div>
  </div>
 
</div>



        </Carousel.Caption>
        
        </Carousel.Item>

        <Carousel.Item>
       
        <div id="secondSlide" className="">
            {/* <img className="d-block w-100 h-100" src={banner1} alt="" /> */}
            </div>
         

          <Carousel.Caption>
         <div className="content-box">
  <div className="content">
    <div className="inner">
    <Zoom>
      <h2>
     <span> Elegance Redefined </span><br />
       
      </h2>
      <div className="text">
      Celebrate Life's Grand Moments at Gurukrupa Party Hall 
      </div>
      </Zoom>
    </div>
  </div>
</div>

        </Carousel.Caption>
        </Carousel.Item>
        
      </Carousel>
      <section className="offers-section-one">
  <div className="auto-container">
   
      
      
    <div className="row clearfix">
    <div className="images-column col-lg-6 col-md-12 col-sm-12">
        <div className="inner">
          <span className="dotted-pattern dotted-pattern-4" />
          <div className="images">
            <div
              className="image"
             
            >
              <img src={fimage1} alt="banquet-hall-kandivali"  />
            </div>
            <div
              className="image wow fadeInRight d-lg-block d-none"
              data-wow-delay="0ms"
              data-wow-duration="1500ms"
             
            >
              <img src={sprouts} alt="banquet-hall-kandivali"  />
            </div>
            <div
              className="image wow fadeInRight d-lg-block d-none"
              data-wow-delay="0ms"
              data-wow-duration="1500ms"
            >
              <img src={food} alt="banquet-hall-kandivali" />
            </div>
          </div>
        </div>
      </div>
      <div className="offers-column col-lg-6 col-md-12 col-sm-12">
      <div className="upper-box clearfix">
      <div className="sec-title">
        {/* <h3>Your Exclusive</h3> */}
        <h2>
        Your Ultimate Celebration Destination
  <br />
 
        </h2>
        <div className="lower-text"><p className="d-none">
Indulge in sophistication and comfort at our establishment, where we redefine hospitality. Immerse yourself in luxury within our meticulously designed 2 master rooms, each equipped with 4 bathrooms, offering a perfect blend of elegance and modern amenities. Your stay with us is bound to be unforgettable, as we prioritize your comfort and well-being.

Our rooms, totaling over 1000 square feet, provide a spacious and inviting atmosphere. Accommodating up to 25-30 visitors, we ensure that every guest experiences the utmost in comfort and convenience. Whether you're seeking a tranquil retreat or a space for social gatherings, our rooms cater to your diverse needs.

Experience personalized service that goes beyond the ordinary, and indulge in the epitome of relaxation throughout your stay. Your journey with us is not merely a stay; it's a celebration of refined hospitality. Discover the perfect fusion of elegance and modernity in our rooms, making your time with us truly exceptional.</p>  <p>
        Welcome to Gurukrupa Party Hall, where we take immense pride in transforming your special moments into unforgettable memories. Situated in the heart of Kandivali, our Party Hall serves as the perfect canvas for your celebrations. Whether it's a lively birthday bash, a grand wedding reception, or any other joyous occasion, Gurukrupa Party Hall is here to cater to all your needs.

<br/>Our state-of-the-art facilities and inviting ambiance set Gurukrupa Party Hall apart as the ideal choice for your next event. Boasting a spacious and well-decorated hall, we offer a versatile space that can be customized to suit your specific requirements. Our dedicated team is committed to ensuring that every detail is perfect, allowing you to relax and fully enjoy the festivities.

Make your celebrations truly special at Gurukrupa Party Hall in Kandivali – where unforgettable memories are created with every event.</p>
        </div>
        <div className="link-box">
        <Link
          to="/banquet-hall-kandivali"
          className="theme-btn btn-style-one read-more"
        >
          Why Choose Us
        </Link>
      </div>
      </div>
    
    </div>
      </div>
    
    </div>
  </div>
</section>
{/* ---------------------------------you get---------------------------- */}

<section id="hww" className=" contentRowPad">
  <div className="container">
  <div className="sectionTitle ">
        <span>Savor the Moment, Stay the Experience</span>
        {/* <h2>About</h2> */}
        
      </div>
    <div className="row">
     
      <div className="col-lg-4 col-12">
      <div className="audit">
      <div className="iconImg">
            <img src={decor}/> 
           
        </div>
        <h5>
    Accomodation      </h5>
        <p>
        In our rooms Immerse yourself in sophistication and comfort as we redefine hospitality. Our meticulously designed 2 master rooms with 4 bathrooms offer a perfect blend of elegance and modern amenities, ensuring an unforgettable stay. We have rooms totaling over 1000sq. feet, and can accommodate up to 25-30 visitors with all comfort and amenities. 
        Experience personalized service and indulge in the epitome of relaxation. Your journey with us is not just a stay; it's a celebration of refined hospitality.</p>

        </div>
      </div>
      <div className="col-lg-4  col-12">
        <div className="audit">
        <div className="iconImg">
            <img src={wedarch} alt="wedding-mandap"/> 
           
        </div>
        <h5>
         Seperate Dinning Area
        </h5>
        <p>
        Our exclusive party hall with a separate dining area! Elevate your event experience with our thoughtfully designed space, ensuring a perfect balance of celebration and culinary delight. Our dedicated dining area offers a private setting for your guests to savor exquisite meals, creating memorable moments in a uniquely tailored environment. Whether it's a corporate gathering, wedding reception, or any special occasion, our separate dining space adds a touch of sophistication to your celebrations.
   </p>
        </div>
      </div>
      <div className="col-lg-4  col-12">
      <div className="audit">
       <div className="iconImg">
            <img src={wedarch} alt="wedding-mandap"/> 
           
        </div>
        <h5>Centrallized A/C Hall
        </h5>
        <p>
        Gurukrupa party hall in kandivali , where timeless elegance meets unrivaled service. As purveyors of unforgettable experiences, we pride ourselves on being more than just a venue; we are curators of exceptional moments. Our centrally air-conditioned hall provides the ideal setting for every occasion. Our state-of-the-art facilities, coupled with culinary expertise, promise an atmosphere that transcends expectations.At Gurukrupa party hall , we redefine elegance, turning dreams into reality.   </p>
        </div>
      </div>
    </div>
   
  </div>
</section>
{/* ------------------------------------Gallery------------------------------- */}
<section className="hallDetails mt-lg-5 p-2 ">
    <div className="container-fluid">
      <div className="sectionTitle ">
        <span>One Venue Fits All Occasion!</span>
        {/* <h2>Events</h2> */}
     
      </div>
      <div className="row ">
      <div className="col-md-3 p-2 ">
          <div className="serviceImg ">
            <img src={wed} alt="banquet-hall-kandivali" />
            <div
              className="imgOverlay"
              data-aos="fade-down"
            >
              <h2  className="">
                Prewedding Function &
                <br/>
                Wedding
              </h2>
            </div>
          </div>
        </div>
        <div className="col-md-3 p-2 ">
          <div className="serviceImg ">
            <img src={bday} alt="banquet-hall-kandivali" />
            <div
              className="imgOverlay"
              data-aos="fade-down"
            >
              <h2  className="">
                Birthday
              </h2>
            </div>
          </div>
        </div>
        <div className="col-md-3 p-2 ">
          <div className="serviceImg ">
            <img src={baby} alt="banquet-hall-kandivali" />
            <div
              className="imgOverlay"
              
            >
              <h2 data-aos="fade-right" className="">
                Baby Shower
              </h2>
            </div>
          </div>
        </div>
        <div className="col-md-3 p-2 ">
          <div className="serviceImg ">
            <img src={haldi} alt="banquet-hall-kandivali" />
            <div
              className="imgOverlay"
              data-aos="fade-down"
            >
              <h2  className="">
                Haldi Ceremony
              </h2>
            </div>
          </div>
        </div>
        <div className="col-md-3 mt-2 p-2 ">
          <div className="serviceImg ">
            <img src={aaniv} alt="banquet-hall-kandivali" />
            <div
              className="imgOverlay"
              
            >
              <h2  className="">
                Anniversary
              </h2>
            </div>
          </div>
        </div>
        <div className="col-md-3 mt-2 p-2 ">
          <div className="serviceImg ">
            <img src={mehandi} alt="banquet-hall-kandivali" />
            <div
              className="imgOverlay"
              
            >
              <h2  className="">
                Mehandi
              </h2>
            </div>
          </div>
        </div>
        <div className="col-md-3 mt-2 p-2">
          <div className="serviceImg ">
            <img src={retire} alt="banquet-hall-kandivali" />
            <div
              className="imgOverlay"
              data-aos="fade-down"
            >
              <h2  className="">
                Retirement party
              </h2>
            </div>
          </div>
        </div>
        <div className="col-md-3 mt-2 p-2">
          <div className="serviceImg ">
            <img src={live} alt="banquet-hall-kandivali" />
            <div
              className="imgOverlay"
              data-aos="fade-down"
            >
              <h2  className="">
                Get Together
              </h2>
            </div>
          </div>
        </div>
      </div>
      {/* <a href="gallery.html">
        {" "}
        <button type="button" className="viewBtn mt-5 mb-3">
          View More
        </button>
      </a> */}
    </div>
    {/* -------------------------------------facilities-------------------- */}
    <section className="whyusSection pt-lg-5 pt-2">
  <div className="container">
  
  <div className="sectionTitle ">
        <span>Facilities</span>
       
      </div>
    <div className="row d-flex justify-content-center align-items-center  pb-lg-5 pb-2 ">
      <div className="col-lg-6 qualityDetails ">
        <ul>
          <li>
            <h2>Customized Decoration</h2>
            <p>
            Our exclusive party hall with a separate dining area! Elevate your event experience with our thoughtfully designed space, ensuring a perfect balance of celebration and culinary delight. Our dedicated dining area offers a private setting for your guests to savor exquisite meals, creating memorable moments in a uniquely tailored environment. Whether it's a corporate gathering, wedding reception, or any special occasion, our separate dining space adds a touch of sophistication to your celebrations. </p>
          </li>
          <li>
            <h2>Valet Parking</h2>
            <p>
            Experience the epitome of convenience at our party hall in Kandivali, where we go the extra mile by offering valet parking services to elevate your event. Our commitment to exceptional service extends to ensuring a hassle-free arrival for you and your guests. Let our professional valet team take care of all parking logistics, allowing you to focus on enjoying your celebration from the moment you arrive. At our party hall in Kandivali, convenience is not just a feature but a commitment to enhancing every aspect of your experience.</p>
          </li>
          {/* <li>

<h2>Power Backup </h2>
<p>
Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
</p>
</li> */}
        </ul>
      </div>
      <div className="col-lg-6 p-lg-0 ">
        <div className="qualityDetails" id=" ">
          <ul>
          {/* <li>
            <h2>Green Room</h2>
            <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
            </p>
          </li> */}
          <li>
            <h2>Indoor-Outdoor Catering</h2>
            <p>
            Discover peace of mind with our CCTV surveillance solutions, specifically tailored for party halls in Kandivali. Our cutting-edge security systems are designed to provide a watchful eye on your events, promoting safety and security throughout. Trust us for state-of-the-art technology, expert installations, and an unwavering commitment to safeguarding your gatherings. At our party hall in Kandivali, your safety is our top priority, ensuring you can focus on enjoying your event with confidence.</p>
          </li>
          
            <li>
              <h2>CCTV Survelliances</h2>
              <p>
              Discover peace of mind with our CCTV surveillance solutions tailored for party halls. Our cutting-edge security systems ensure a watchful eye on your event, promoting safety and security. Trust us for state-of-the-art technology, expert installations, and a commitment to safeguarding your gatherings. Your safety, our priority."   </p>
            </li>
          
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>

  </section>
{/* ---------------------------------------form section------------------ */}
  <section
  className="contactSection mt-lg-5 mt-2"
  
>
  <div className="container ">
    <div className="row  ">
      <div className="col-lg-6">
       
      </div>
      <div className="col-lg-6 pt-5 pb-5">
       
          <h3 />
          {/* <h4>Contact us for custom quote</h4> */}
          <Formik
                      initialValues={{ email: "", Name: "", Cnumber: "",  message: "" }}
                      validate={(values) => {
                        const errors = {};
                        if (!values.Name) {
                          errors.Name = "Required";
                        } else if (values.Name.length >= 10) {
                          errors.Name = "Enter A Name";
                        }

                        if (!values.Cnumber) {
                          errors.Cnumber = "Required";
                        } else if (values.Cnumber.length >= 11) {
                          errors.Name = "Invalid Contact Number";
                        }

                        if (!values.email) {
                          errors.email = "Required";
                        } else if (
                          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                            values.email
                          )
                        ) {
                          errors.email = "Invalid email address";
                        }
                        return errors;
                      }}
                      onSubmit={(values, { setSubmitting, resetForm }) => {
                        // setTimeout(() => {

                        var body =
                          '<!DOCTYPE html><html><head><title>Enquiry Lead</title></head><body><div style="font-family:Arial,sans-serif;max-width:600px;margin:0 auto;background-color:#f2f2f2;padding:20px"><h2 style="color:#eb6e14">Gurukrupa Party Hall Enquiry</h2><p>Hello</p><p>Thank you for your interest in our products/services.</p><p>Please check your enquiry which generated from website:</p><table cellpadding="5" style="margin:0"><tr><td style="text-align:left;color:#0a500a"><strong>Name:</strong></td><td style="text-align:left">' +  values.Name + '</td></tr><tr><td style="text-align:left;color:#0a500a"><strong>Email Id:</strong></td><td style="text-align:left">' +  values.email + '</td></tr><tr><td style="text-align:left;color:#0a500a"><strong>Contact No:</strong></td><td style="text-align:left">' +  values.Cnumber + '</td></tr><tr><td style="text-align:left;color:#0a500a"><strong>Message:</strong></td><td style="text-align:left">' +  values.message + '</td></tr></table><p style="font-weight:700">Best regards,<br>Your Team at Gurukrupa Party Hall</p></div></body></html>';

                        $.post(
                          "https://skdm.in/server/v1/send_lead_mail.php",
                          {
                          
                            toEmail: "gurukrupaparythall@gmail.com",
                            fromEmail: "skdmlead@gmail.com",
                            bccMail: "skdmlead@gmail.com",
                            mailSubject: "New Lead genration",
                            mailBody: body,
                            accountName: "gurukrupahall",
                            accountLeadSource:
                              "",
                          },

                          function (dataa, status) {
                            // console.log('data :' + dataa);
                            // console.log("name:" + custName);
                          }
                        );

                        alert(
                          "Your Form has Submitted Our team will contact with You  soon."
                        );

                        // e.preventDefault()

                        setSubmitting(false);
                          // Reset the form after submission
  resetForm();

                        // }, 10);
                      }}
                    >
                      <Form id="contact">
                    <Field
                      name="Name"
                      type="text"
                      
                      placeholder="Your name"
                    />
                     <ErrorMessage name="Name" component="div" />
                     <Field
                     name="Cnumber"
                     placeholder="Your Phone Number*"
                     type="tel"
                     tabIndex={3}
                     required=""
                    />
                      <ErrorMessage name="Cnumber" component="div" /> 
                    <Field
                    name="email"
                      type="text"
                     
                      placeholder="Email"
                    />
                     <ErrorMessage name="email" component="div" />
                    <Field  as="textarea"
                     name="message"
                     
                      placeholder="Message"
                      defaultValue={""}
                    />
                      <ErrorMessage name="message" component="div" />
                    <button className="contact_form_submit" type="submit" >Send</button>
                    </Form>
              </Formik>
          {/* <fieldset>
            <input
              placeholder="Your name"
              type="text"
             
              required=""
             
            />
          </fieldset>
          <fieldset>
            <input
              placeholder="Your Phone Number*"
              type="tel"
              tabIndex={3}
              required=""
            />
          </fieldset>
          <fieldset>
            <input
              placeholder="Date of Event"
              type="email"
              tabIndex={2}
              required=""
            />
          </fieldset>
          <fieldset>
            <input
              placeholder="Type of event"
              type="email"
              tabIndex={2}
              required=""
            />
          </fieldset>
          <fieldset>
            <input
              placeholder="No.of guest"
              type="url"
              tabIndex={4}
              required=""
            />
          </fieldset>
          <fieldset>
            <textarea
              placeholder="Type your message here...."
              tabIndex={5}
              required=""
              defaultValue={""}
            />
          </fieldset>
          <fieldset>
            <button
              name="submit"
              type="submit"
              id="contact-submit"
              data-submit="...Sending"
            >
              Submit
            </button>
          </fieldset> */}
       
      </div>
    </div>
  </div>
</section>

<Footer/>
    </div>
  )
}

export default Home