import React from 'react';
import './App.css';
import {  Routes, Route, BrowserRouter } from 'react-router-dom';
import NavBar from "./Components/Navbar"
import Home from './Pages/Home';
import About from './Pages/About';
// import Rooms from './Pages/Rooms';
import Service from "./Pages/Service";
import Gallery from './Pages/Gallery';
import Contact from './Pages/Contact';
function App() {
  return (
    <BrowserRouter>
    <NavBar/>
      <Routes>
        <Route path='/' element={<Home/>}/> 
         <Route path='/banquet-hall-kandivali' element={<About/>}/>
         <Route path='/100-150-capacity-hall-kandivali' element={<Service/>} />



         <Route path='/best-banquet-hall-kandivali' element={<Gallery/>} /> 
         <Route path='/best-hall-kandivali' element={<Contact/>} /> 
       

      </Routes>
    </BrowserRouter>
  );
}
export default App;
