import React, { useLayoutEffect } from "react";
import "../Css/About.css";
import Footer from "../Components/Footer.js";
import {MetaTags} from "react-meta-tags";
const About = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div>
       {/* ========= SEO ========== */}

    <MetaTags>
        <title>
        Banquet Hall in Kandivali | Gurukrupa Party Hall
        </title>
        <meta name="title" title="Banquet Hall in Kandivali | Gurukrupa Party Hall"/>
        <meta
          property="og:title"
          content="Banquet Hall in Kandivali | Gurukrupa Party Hall"
        />
        <meta
          name="description"
          content="Discover the exquisite Banquet Hall in Kandivali, perfect for hosting memorable events. Book your ideal venue for weddings, parties, and special occasions."
        />
       
        <meta
          property="og:description"
          content="Discover the exquisite Banquet Hall in Kandivali, perfect for hosting memorable events. Book your ideal venue for weddings, parties, and special occasions."
        />
        <meta
          name="keywords"
          content="Party Hall in Kandivali,
          Banquet Hall in Kandivali,
          Best Banquet Hall in Kandivali,
          Top Party Hall in Kandivali,
          Best Hall in Kandivali"
        />
<meta name="google-site-verification" content="GrMY0Kd9JZI27nSpKcOw0ZtFCtHS5dc4sS6bAuhKJdk" />
        <meta property="article:tag" content="Best Hall in Kandivali" />
        <meta property="article:tag" content="Top Party Hall in Kandivali" />
        <meta property="article:tag" content="Best Banquet Hall in Kandivali" />
        <link rel="canonical" href="https://gurukrupapartyhall.in/banquet-hall-kandivali" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
      
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="" />
       
        
      </MetaTags>

      {/* ======== Seo end ======= */}
      {/* --------------------------banner Section----------------------------- */}
      <section className="headingSection">
        <div className=" ">
          <div
            className="container d-flex justify-content-end align-content-center aos-init aos-animate"
            data-aos="fade-right"
          >
            <div className="headingText">
              <h1 className="d-none">Banquet Hall in Kandivali | Gurukrupa Party Hall</h1>
              <h2>Our Story</h2>
              {/* <p>
          Malad de Grande offers Specialized, All-Inclusive Amenities and
          Services which set us apart from the competition. We have considered
          every detail and perfected your experience at Malad de Grande. We take
          pride in the versatility and quality of our venue and services. Our
          management and staff work tirelessly to ensure that all events go
          above and beyond your expectations. Whether it’s a Corporate Event or
          your Wedding Day, let’s make it a One-of-a-Kind experience.
        </p> */}
            </div>
          </div>
        </div>
      </section>
      {/* -------------------------about us section------------------- */}
      <section className="aboutSection mt-5" id="about_us">
        <div className="container ">
          {/*  */}
          <div className="sectionTitle ">
        <span>An incredible experience</span>
       
      </div>
      <div className="aboutTitle aos-init aos-animate" data-aos="fade-down">
            <p>
            Welcome to our Gurukrupa party hall in Kandivali, where elegance seamlessly meets convenience. Our banquet hall offers classy and versatile spaces, perfect for any event on your calendar, whether it's an intimate engagement party, a grand anniversary bash, or corporate events accommodating 100-200 people. With thoughtfully curated interiors and state-of-the-art facilities, we ensure a memorable experience for every occasion.

At Gurukrupa party hall, accommodations are available to provide comfort for you and your guests. We boast 2 rooms totaling over 1000 square feet, capable of accommodating up to 25-30 visitors, ensuring a spacious and comfortable environment with all the necessary amenities. Discover a perfect blend of sophistication and functionality at our venue – your ideal choice for celebrations and events in Kandivali.

Make your moments truly special by choosing our Banquet Hall in Kandivali, where we strive to create the perfect ambiance for your celebrations, blending sophistication with functionality for an unforgettable experience.







           
            </p>
          </div>
          <div className="row pt-5"></div>
        </div>
      </section>

      <div className="clearfix">&nbsp;</div>
      <section className="mb-5">
        <div className="container">
          <div className="row">
            {/* <div className="featureBoxes alterText"> */}
              <div className="col-md-3">
                <div className="featureBoxItem featureBox-Success hight2x animated slideInLeft mb20">
                 <h3>Why choose us</h3>
                  {/* <i className="fa fa-users" />
                  <div className="caption"><h6>Value our clients</h6></div> */}
                  <p className="text-center">
                
Welcome to Gurukrupa Party Hall, where we don't just host events; we cherish every moment, considering each client as the heartbeat of our exceptional celebration experience. Our commitment extends beyond the event itself as we continually strive to exceed expectations and create lasting memories. Choose Gurukrupa Party Hall in Kandivali, your premier Banquet Hall destination, where every celebration becomes a unique and unforgettable experience.</p>
                </div>
              </div>
              <div className="col-md-9">
                <div className="row">
                  <div className="col-md-6 mt-2">
                    <div className="featureBoxItem animated pulse mb20">
                      <i className="fa fa-credit-card"/>
                      <div className="caption">
                        <h6>Budget Friendly</h6>
                      </div>
                      <p className="text-center">
                      Creating memorable experiences within your budget.

                      </p>
                    </div>
                  </div>
                  <div className="col-md-6 mt-2">
                    <div className="featureBoxItem animated pulse mb20">
                      <i className="fa fa-lock" />
                      <div className="caption"><h6>Security</h6></div>
                      <p className="text-center">
                      Choose us for a perfect blend of value with security in hosting your special moments.
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6 mt-2">
                    <div className="featureBoxItem animated pulse mb20">
                      <i className="fa fa-book" />
                      <div className="caption">
                        <h6>Easy Booking</h6>
                      </div>
                      <p className="text-center">
                      Enjoy seamless bookings with our user-friendly process, and rest assured with our top-notch security measures.

                      </p>
                    </div>
                  </div>
                  <div className="col-md-6 mt-2">
                    <div className="featureBoxItem animated pulse mb20">
                      <i className="fa fa-check" />
                      <div className="caption">
                      <h6>Impeccable Service</h6>
                      </div>
                      <p className="text-center">
                    
Impeccable service is our standard, ensuring your event at our party hall is not only stress-free but exceeds expectations. 

                      </p>
                    </div>
                  </div>
                 
                </div>
              </div>
            {/* </div> */}
          </div>
        </div>
      </section>
{/* ----------------------------mission ------------------------ */}
<section className="featureSection pt-5">
  <div className="container">
    <div className="row">
      <div className="col-lg-6 col-12">
        <div className="featureHead">
          <h2>Mission-Vision</h2>
          <p>
          
At our banquet hall in Kandivali, we strive to create unforgettable moments by providing a versatile and enchanting space where celebrations come to life. Our mission is to be the preferred venue for joyous occasions, offering exceptional service, a vibrant atmosphere, and meticulous attention to every detail. We ensure that every event held at our banquet hall is a cherished and seamless experience for our guests. Choose us as your preferred banquet hall in Kandivali, where we are dedicated to making your celebrations truly special.

          </p>
        </div>
      </div>
      <div className="col-lg-6">
       
      </div>
    </div>
  </div>
</section>

      <Footer />
    </div>
  );
};

export default About;
