import React, { useLayoutEffect } from "react";
import "../Css/Contact.css";
import Footer from "../Components/Footer";
import { Formik, Form, Field, ErrorMessage } from "formik";
import $ from 'jquery';
import {MetaTags} from "react-meta-tags";
const Contact = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div>
      {/* ========= SEO ========== */}

    <MetaTags>
        <title>
        Top Party Hall in Kandivali | Gurukrupa Party Hall
        </title>
        <meta name="title" title="Top Party Hall in Kandivali | Gurukrupa Party Hall"/>
        <meta
          property="og:title"
          content="Top Party Hall in Kandivali | Gurukrupa Party Hall"
        />
        <meta
          name="description"
          content="Discover the ultimate venue for your celebrations – the Top Party Hall in Kandivali. Elevate your events with vibrant spaces and exceptional service."
        />
       
        <meta
          property="og:description"
          content="Discover the ultimate venue for your celebrations – the Top Party Hall in Kandivali. Elevate your events with vibrant spaces and exceptional service. "
        />
        <meta
          name="keywords"
          content="Party Hall in Kandivali,
          Banquet Hall in Kandivali,
          Best Banquet Hall in Kandivali,
          Top Party Hall in Kandivali,
          Best Hall in Kandivali"
        />
<meta name="google-site-verification" content="GrMY0Kd9JZI27nSpKcOw0ZtFCtHS5dc4sS6bAuhKJdk" />
        <meta property="article:tag" content="Best Hall in Kandivali" />
        <meta property="article:tag" content="Top Party Hall in Kandivali" />
        <meta property="article:tag" content="Best Banquet Hall in Kandivali" />
        <link rel="canonical" href="https://gurukrupapartyhall.in/top-party-hall-kandivali" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
      
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="" />
       
        
      </MetaTags>

      {/* ======== Seo end ======= */}
        <section className="ContactBlock">
  <div className="contactCard  ">
    <div className="container ">
      <div className="row d-flex justify-content-center align-items-center flex-column">
        <div className="connectInfo">
          <h2>Contact us</h2>
          <h2>Let's build something amazing together.</h2>
          {/* <a href="#contactfill">
            {" "}
            <button type="button" className="connectbtn">
              Fill Details{" "}
            </button>
          </a> */}
        </div>
      </div>
    </div>
  </div>
</section>
{/* ---------------------details------------------------ */}
<section className="mt-lg-5  m-2">
  <div className="  ">
    <div className="container ">
      <div className="row  ">
        <div className="col-md-4 col-12 p-0 m-0 d-flex justify-content-center align-items-center">
        <a href="mailto:gurukrupaparythall@gmail.com">
          <div className="contactIcon">
              <div className="contactCircle">
                <i className="fa fa-envelope " />
              </div>
           
            <p>gurukrupaparythall@gmail.com</p>
          </div>
          </a>
        </div>
        <div className="col-md-4  col-12 p-0 m-0 d-flex justify-content-center align-items-center">
        <a href="https://maps.app.goo.gl/jZTKhaN25xAuKV1b7">
          <div className="contactIcon ">
         
              <div className="contactCircle">
                <i className="fa fa-map-marker " />
              </div>
          
            <p>
            Bunglow No, 10, RSC Rd Number 55, near Oxford Public School, Kandivali, Sector 5 Charkop, Kandivali West, Mumbai, Maharashtra 400067
            </p>
          </div>  </a>
        </div>
        <div className="col-md-4  col-12 p-0 m-0 d-flex justify-content-center align-items-center">
        <a href="tel:+918169684124">
          <div className="contactIcon ">
          
              <div className="contactCircle">
                <i className="fa fa-phone-square " />
              </div>
           
            <p>8169684124 / 8169347797</p>
          </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</section>
{/* ---------------------------service section---------------------- */}
<section className="serviceSection mt-5 " id="contactfill">
  <div className="container ">
    <div className="contactText pt-5">
      <h2 className=" " />
    </div>
    <div className="row d-flex justify-content-center mt-5 ">
      {/* <p>Please use this form if you have any questions about our products and we'll get back with you very soon.</p> */}
      <div className="col-lg-6 col-12 ">
        {/* <div class="contact-section "> */}
        <Formik
                      initialValues={{ email: "", Name: "", Cnumber: "",  message: "" }}
                      validate={(values) => {
                        const errors = {};
                        if (!values.Name) {
                          errors.Name = "Required";
                        } else if (values.Name.length >= 10) {
                          errors.Name = "Enter A Name";
                        }

                        if (!values.Cnumber) {
                          errors.Cnumber = "Required";
                        } else if (values.Cnumber.length >= 11) {
                          errors.Name = "Invalid Contact Number";
                        }

                        if (!values.email) {
                          errors.email = "Required";
                        } else if (
                          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                            values.email
                          )
                        ) {
                          errors.email = "Invalid email address";
                        }
                        return errors;
                      }}
                      onSubmit={(values, { setSubmitting, resetForm }) => {
                        // setTimeout(() => {

                        var body =
                          '<!DOCTYPE html><html><head><title>Enquiry Lead</title></head><body><div style="font-family:Arial,sans-serif;max-width:600px;margin:0 auto;background-color:#f2f2f2;padding:20px"><h2 style="color:#eb6e14">Gurukrupa Party Hall Enquiry</h2><p>Hello</p><p>Thank you for your interest in our products/services.</p><p>Please check your enquiry which generated from website:</p><table cellpadding="5" style="margin:0"><tr><td style="text-align:left;color:#0a500a"><strong>Name:</strong></td><td style="text-align:left">' +  values.Name + '</td></tr><tr><td style="text-align:left;color:#0a500a"><strong>Email Id:</strong></td><td style="text-align:left">' +  values.email + '</td></tr><tr><td style="text-align:left;color:#0a500a"><strong>Contact No:</strong></td><td style="text-align:left">' +  values.Cnumber + '</td></tr><tr><td style="text-align:left;color:#0a500a"><strong>Message:</strong></td><td style="text-align:left">' +  values.message + '</td></tr></table><p style="font-weight:700">Best regards,<br>Your Team at Gurukrupa Party Hall</p></div></body></html>';

                        $.post(
                          "https://skdm.in/server/v1/send_lead_mail.php",
                          {
                          
                            toEmail: "gurukrupaparythall@gmail.com",
                            fromEmail: "skdmlead@gmail.com",
                            bccMail: "skdmlead@gmail.com",
                            mailSubject: "New Lead genration",
                            mailBody: body,
                            accountName: "gurukrupahall",
                            accountLeadSource:
                              "",
                          },

                          function (dataa, status) {
                            // console.log('data :' + dataa);
                            // console.log("name:" + custName);
                          }
                        );

                        alert(
                          "Your Form has Submitted Our team will contact with You  soon."
                        );

                        // e.preventDefault()

                        setSubmitting(false);
                          // Reset the form after submission
  resetForm();

                        // }, 10);
                      }}
                    >
                      <Form id="contact">
                    <Field
                      name="Name"
                      type="text"
                      
                      placeholder="Your name"
                    />
                     <ErrorMessage name="Name" component="div" />
                     <Field
                     name="Cnumber"
                     placeholder="Your Phone Number*"
                     type="tel"
                     tabIndex={3}
                     required=""
                    />
                      <ErrorMessage name="Cnumber" component="div" /> 
                    <Field
                    name="email"
                      type="text"
                     
                      placeholder="Email"
                    />
                     <ErrorMessage name="email" component="div" />
                    <Field  as="textarea"
                     name="message"
                     
                      placeholder="Message"
                      defaultValue={""}
                    />
                      <ErrorMessage name="message" component="div" />
                    <button className="contact_form_submit" type="submit" >Send</button>
                    </Form>
              </Formik>
      </div>
      {/* </div> */}
      <div className="col-lg-6 col-12">
      <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d60294.941037095385!2d72.78937720794598!3d19.176245554341932!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b6b4ddc88599%3A0xa6e4c03743071926!2sGurukrupa%20Party%20Hall!5e0!3m2!1sen!2sin!4v1700287268078!5m2!1sen!2sin"
          width="100%"
          height={350}
          style={{ border: 0 }}
          allowFullScreen=" "
          loading="lazy "
        />
      </div>
    </div>
  </div>
</section>
{/* ------------------------------get section--------------- */}

<Footer/>
    </div>
  )
}

export default Contact