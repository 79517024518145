import React, { useState } from "react";
import "../Css/Navbar.css";
import { Nav, Navbar, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import Logo from "../Images/gallery/jpeg-optimizer_Gurukrupa.png";


const NavBar = () => {
  const [color, setColor] = useState(false);

  const changeColor = () => {
    if (window.scrollY >= 78) {
      setColor(true);
    } else {
      setColor(false);
    }
  };

  window.addEventListener("scroll", changeColor);
  return (
    <div>
      <section className="NavSection">
        <div className="container">
          <Navbar
            collapseOnSelect
            expand="lg"
            variant="dark"
            className={color ? "NavBar NavBar-bg" : "NavBar"}
          >
            <Container>
              <Navbar.Brand href="/">
                <img src={Logo} alt="" />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse id="responsive-navbar-nav" className="main-nav">
                <Nav>
                 
                <Nav.Link
                href="/"
                className="nav-link"
              >  <Link
              to="/"
              className="nav-link"
            >  Home <span><i classname="fa fa-arrow-right"></i></span>
            </Link>
            
              </Nav.Link>
              
                
                    <Nav.Link  className="nav-link" href="/banquet-hall-kandivali"> <Link  className="nav-link" to="/banquet-hall-kandivali">About Us</Link>
                 </Nav.Link>
                   
                    <Nav.Link  className="nav-link"  href="/best-banquet-hall-kandivali"> <Link  className="nav-link"  to="/best-banquet-hall-kandivali">Gallery</Link></Nav.Link>
                   
                
                    <Nav.Link  className="nav-link"  href="/100-150-capacity-hall-kandivali">                 
                    <Link  className="nav-link"  to="/100-150-capacity-hall-kandivali">Services</Link></Nav.Link>

                 
                    <Nav.Link  className="nav-link"  href="/best-hall-kandivali"> <Link  className="nav-link"  to="/best-hall-kandivali">Contact Us</Link></Nav.Link>
                   
                 
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </div>
      </section>

    </div>
  )
}


export default NavBar

 

